<template>
  <div id="web-statistics-page">
    <a-card title="Sessions">
      <BasicLineChart
        :dataSource="dataSource"
        xField="Date"
        yField="scales"
      ></BasicLineChart>
    </a-card>
    <br/>
    <a-card title="Users">
      <BasicLineChart
        :dataSource="dataSource"
        xField="Date"
        yField="scales"
      ></BasicLineChart>
    </a-card>
    <br/>
    <a-card title="Stores">
      <BasicLineChart
        :dataSource="dataSource"
        xField="Date"
        yField="scales"
      ></BasicLineChart>
    </a-card>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined,  } from '@ant-design/icons-vue';
import BasicLineChart from '../../components/charts/BasicLineChart.vue';

export default {
  name:"WebStatisticsPage",
  components:{
    BasicLineChart
  },

  setup(props, context){
    const dataSource = [
      {
        "Date": "2010-01",
        "scales": 1998
      },
      {
        "Date": "2010-02",
        "scales": 1850
      },
      {
        "Date": "2010-03",
        "scales": 1720
      },
      {
        "Date": "2010-04",
        "scales": 1818
      },
      {
        "Date": "2010-05",
        "scales": 1920
      }];

    return { dataSource }
  },

}
</script>

<style>
#web-statistics-page .ant-card-head-title{
  display: block;
  flex: none;
}

#web-statistics-page{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
</style>