<template>
  <div class="BusinessHub">
    <!-- First row: Global Revenue -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Global Revenue</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="grThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="grThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="grLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="grThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="grLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>
    
    <!-- 2 row: Card Commisions -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Card Commisions</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="ccThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="ccThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="ccLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="ccThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="ccLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>
    
    <!-- 3 row: Kronocard Pro -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Kronocard Pro</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="kpThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="kpThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="kpLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="kpThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="kpLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>
    
    <!-- 4 row: Subscriptions -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Subscriptions</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="spThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="spThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="spLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="spThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="spLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>
    
    <!-- 5 row: Scanners -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Scanners</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="scThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="scThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="scLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="scThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="scLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>   
    </a-row>
    
    <!-- 6 row: eBay/Kronozio Sold -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>eBay/Kronozio Sold</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Year -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Year"
            :value="ekThisYear"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="ekThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="ekLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="ekThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="ekLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col> 
    </a-row>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
export default {
  name:"FinancialHubPage",
  components:{
  },

  data(){
    return {
      // Global Revenue
      grThisYear: 0,
      grThisMonth: 0,
      grLastMonth: 0,
      grThisWeek: 0,
      grLastWeek: 0,

      // Card Commisions
      ccThisYear: 0,
      ccThisMonth: 0,
      ccLastMonth: 0,
      ccThisWeek: 0,
      ccLastWeek: 0,

      // Kronocard Pro
      kpThisYear: 0,
      kpThisMonth: 0,
      kpLastMonth: 0,
      kpThisWeek: 0,
      kpLastWeek: 0,

      // Subscriptions
      spThisYear: 0,
      spThisMonth: 0,
      spLastMonth: 0,
      spThisWeek: 0,
      spLastWeek: 0,

      // Scanners
      scThisYear: 0,
      scThisMonth: 0,
      scLastMonth: 0,
      scThisWeek: 0,
      scLastWeek: 0,

      // eBay/Kronozio Card Sold
      ekThisYear: 0,
      ekThisMonth: 0,
      ekLastMonth: 0,
      ekThisWeek: 0,
      ekLastWeek: 0
    }
  },

  setup(props, context){
    

    return { }
  },

}
</script>

