<template>
    <div>
        <div id="bar-container"></div>
    </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUpdated, onBeforeUnmount, inject } from 'vue'
import { Mix, G2 } from '@antv/g2plot';

//import { onMounted, onUpdated } from '@vue/runtime-core';
export default defineComponent({
  name: "BarChart",
  props:{
    dataSource: Array,
    groupBy: Array,
    xField: Array,
    yField: Array,
    types: Array,
    height: Number,
  },

  // entry point of the component
  setup(props){
    
    const isEmpty = inject("isEmpty");

    // initialize the lineChart ref
    const chart = ref('');

    // create the chart object
    const createChart = () => {
        let counter = 0;
        let options = {
            tooltip: false,
            height: props.height ? props.height : 600,
            syncViewPadding: true,
        }

        let plots = [
            {
                type: 'bar',
                region: { start: { x: 0 , y: 0 }, end: { x: 1, y: 1 } },
                options: {
                    data: props.dataSource,
                    columnWidthRatio: 0.8,
                    xField: props.xField,
                    yField: props.yField,
                    color: "#f68205",
                    meta: {
                        time: { range: [0, 1] },
                    },
                    tooltip: {
                        showCrosshairs: true,
                        shared: true,
                    },
                },
            }
        ];

        options["plots"] = plots;
        return options;
    }

    // render or re-render the chart
    const renderChart = () => {
        let options = createChart();
        chart.value.update(options);
    };

    onMounted(() => {
        console.log("mounted");
        let options = createChart();
        chart.value = new Mix('bar-container', options);
        chart.value.render();
        
    });

    onUpdated(renderChart);

    onBeforeUnmount(() => {
        chart.value.destroy();
    });

    return {isEmpty};
  }
})
</script>

<style>

</style>
