<template>
  <div class="kronocard-activity">
    
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom" :gutter="[16,16]">
          
            <a-col :span="20">
              <a-card>
                <a-row justify="start" align="bottom">
                  <a-col :span="12" style="background:#fff">
                    <a-descriptions bordered size="small">
                      <a-descriptions-item label="From" :span="1">{{ timeRange[0] ? new Date(timeRange[0]).toLocaleDateString() : "-" }} </a-descriptions-item>
                      <a-descriptions-item label="To" :span="1">{{ timeRange[1] ? new Date(timeRange[1]).toLocaleDateString(): "-" }}</a-descriptions-item>
                    </a-descriptions>
                  </a-col>

                  <a-col :span="6" />

                  <a-col :span="4">
                    <date-picker
                      v-model = "timeRange"
                      @time-selected="timeRangeChange"
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
    </a-row>
    
    <a-row style="width:100%; margin-top:15pt" justify="center" :gutter="[16,16]">
      <a-col :span="22">
          <a-tabs v-model:activeKey="activeKey" tab-position="left">
            <a-tab-pane key="1" tab="Card Sold">
              <card-sold-chart />
            </a-tab-pane>
            <a-tab-pane key="2" tab="Card Scanned">
              <card-scan-chart />
            </a-tab-pane>
            <a-tab-pane key="3" tab="Card Listed">
              <card-listed-chart />
            </a-tab-pane>
            <a-tab-pane key="4" tab="Launches">
              <kronocard-open-chart />
            </a-tab-pane>
            <a-tab-pane key="5" tab="Users">
              <user-chart />
            </a-tab-pane>
          </a-tabs>
      </a-col>
    </a-row>
  </div>
  
</template>

<script>
import CardSoldChart from '../../components/chart_builder/CardSoldChart.vue';
import CardScanChart from '../../components/chart_builder/CardScanChart.vue';
import CardListedChart from '../../components/chart_builder/CardListedChart.vue';
import KronocardOpenChart from '../../components/chart_builder/KronocardOpenChart.vue';
import UserChart from '../../components/chart_builder/UserChart.vue';
import DatePicker from '../../components/time_selector/DatePicker.vue';

import { ref, onMounted, getCurrentInstance, provide, inject } from 'vue';

export default {
  name:"KronocardActivityPage",
  components:{
    DatePicker,
    CardSoldChart,
    CardScanChart,
    CardListedChart,
    KronocardOpenChart,
    UserChart
  },

  setup(props, context){
    /* time range related */
    const timeRange = ref([new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toString(), new Date().toString()]);
    provide("timeRange", timeRange)
    const timeRangeChange = (value) => {
      console.log(value)
      timeRange.value = value;
    }

    /* Tab related */
    const activeKey = ref('1')
    return { timeRange, timeRangeChange}
  },

  mounted(){
    
  }
  
}
</script>

<style>
.installs{
  margin-top: 20pt;
}

</style>