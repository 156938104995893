import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Test from '../views/Test.vue'
import Dashboard from '../views/Dashboard.vue';
import UninstallSurvey from '../views/UninstallSurvey.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/uninstall-survey/:installation_id/:activity_id',
    name: 'uninstall-survey',
    component: UninstallSurvey
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    children:[
      
    ],
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
