<template>
  <a-layout id="components-layout-demo-responsive">
    <a-layout-sider
      breakpoint="xs"
      collapsed-width="0"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
      width="250"
      theme="light"
      :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
    >
      <div class="logo" style="text-align: start;">
        <p id="logo-text">
            Kronocard <br>
            Admin Panel
        </p>
      </div>
      <a-menu theme="light" mode="inline" v-model:selectedKeys="selectedKeys" style="text-align:start; margin-top:10pt !important;">
        <a-menu-item key="0">
          <HomeOutlined />
          <span class="nav-text">Home</span>
        </a-menu-item>

        <a-menu-item key="1">
          <DownloadOutlined />
          <span class="nav-text">Installs</span>
        </a-menu-item>

        <a-menu-item key="2">
          <SwitcherOutlined />
          <span class="nav-text">Versions/OS/Language</span>
        </a-menu-item>

        <a-menu-item key="3">
          <CompassOutlined />
          <span class="nav-text">Geographic Distribution</span>
        </a-menu-item>

        <a-menu-item key="4">
          <HeartOutlined />
          <span class="nav-text">Kronocard Pro</span>
        </a-menu-item>

        <a-menu-item key="5">
          <AppstoreOutlined />
          <span class="nav-text">Optional Products</span>
        </a-menu-item>

        <a-menu-item key="6">
          <ExperimentOutlined />
          <span class="nav-text">AI Activities</span>
        </a-menu-item>

        <a-menu-item key="7">
          <FrownOutlined />
          <span class="nav-text">Uninstall Survey</span>
        </a-menu-item>

        <a-menu-item key="8">
          <FunctionOutlined />
          <span class="nav-text">Kronocard Activities</span>
        </a-menu-item>

        <a-menu-item key="9">
          <UserOutlined />
          <span class="nav-text">User Informations</span>
        </a-menu-item>

        <a-sub-menu key="sub1">
          <template #icon>
            <DashboardOutlined />
          </template>
          <template #title>Hubs</template>
          <a-menu-item key="10">
            <AreaChartOutlined />
            <span class="nav-text">Business Hub</span>
          </a-menu-item>

          <a-menu-item key="11">
            <RiseOutlined />
            <span class="nav-text">Financial Hub</span>
          </a-menu-item>

          <a-menu-item key="12">
            <PieChartOutlined />
            <span class="nav-text">Web Statistics</span>
          </a-menu-item>

          <a-menu-item key="13">
            <LikeOutlined />
            <span class="nav-text">Social Network</span>
          </a-menu-item>

          <a-menu-item key="14">
            <DatabaseOutlined />
            <span class="nav-text">Cards in Database</span>
          </a-menu-item>

          <a-menu-item key="15">
            <DollarCircleOutlined />
            <span class="nav-text">Revenue</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '250px' }">
      <!-- Header 
      <a-layout-header :style="{ background:'#f68205', padding: 0, }">
      </a-layout-header>
      -->
      <!-- Content -->
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <!-- registeration zone. new pages that is displaying on the dashboard, has to be
          registered here
        
        <router-view 
          v-if="this.$route.path.includes('monitor')"
          :style="{ padding: '24px', minHeight: '83vh'}">
        </router-view>
        -->
        <!-- end of registeration zone -->

        <div 
          :style="{ padding: '24px', minHeight: '90vh'}" 
        >
          <home-page v-if="selectedKeys=='0'"/>
          <installs-page v-if="selectedKeys=='1'"/>
          <version-page v-else-if="selectedKeys=='2'"/>
          <geographic-page v-else-if="selectedKeys=='3'"/>
          <pro-page v-else-if="selectedKeys=='4'"/>
          <optional-product-page v-else-if="selectedKeys=='5'"/>
          <ai-page v-else-if="selectedKeys=='6'"/>
          <uninstall-survey-page v-else-if="selectedKeys=='7'"/>
          <kronocard-activity-page v-else-if="selectedKeys=='8'"/>
          <user-info-page v-else-if="selectedKeys=='9'"/>
          <business-hub-page v-else-if="selectedKeys=='10'"/>
          <financial-hub-page v-else-if="selectedKeys=='11'"/>
          <web-statistics-page v-else-if="selectedKeys=='12'"/>
          <social-network-page v-else-if="selectedKeys=='13'"/>
          <cards-in-database-page v-else-if="selectedKeys=='14'"/>
          <revenues-page v-else-if="selectedKeys=='15'"/>
        </div>
        
      </a-layout-content>

      <!-- Footer -->
      <a-layout-footer style="textAlign: center; bottom:0;">
        Kronocard Admin Panel ©2021 Created by Yizhou Zhao
      </a-layout-footer>
      
    </a-layout>
  </a-layout>
</template>


<script>
// Icons
import { 
  DownloadOutlined, 
  SwitcherOutlined, 
  CompassOutlined, 
  DollarCircleOutlined, 
  AppstoreOutlined, 
  ExperimentOutlined,
  FrownOutlined,
  FunctionOutlined,
  UserOutlined,
  HomeOutlined,
  AreaChartOutlined,
  RiseOutlined,
  PieChartOutlined,
  StockOutlined,
  HeartOutlined,
  LikeOutlined,
  DatabaseOutlined,
  DashboardOutlined
} from '@ant-design/icons-vue';

// import dashboard pages
import HomePage from './dashboard_pages/00_Home.vue';
import InstallsPage from './dashboard_pages/01_Installs.vue';
import VersionPage from './dashboard_pages/02_Version.vue';
import GeographicPage from './dashboard_pages/03_Geographic.vue';
import ProPage from './dashboard_pages/04_Pro.vue';
import OptionalProductPage from './dashboard_pages/05_OptionalProduct.vue';
import AiPage from './dashboard_pages/06_AI.vue';
import UninstallSurveyPage from './dashboard_pages/07_UninstallSurvey.vue';
import KronocardActivityPage from './dashboard_pages/08_KronocardActiity.vue';
import UserInfoPage from './dashboard_pages/09_UserInfo.vue';
import BusinessHubPage from './dashboard_pages/10_BusinessHub.vue';
import FinancialHubPage from './dashboard_pages/11_FinancialHub.vue';
import WebStatisticsPage from './dashboard_pages/12_WebStatistics.vue';
import SocialNetworkPage from './dashboard_pages/13_SocialNetwork.vue';
import CardsInDatabasePage from './dashboard_pages/14_CardsInDatabase.vue';
import RevenuesPage from './dashboard_pages/15_Revenues.vue';

export default {
    name: "Dashboard",

    data() {
        return {
            selectedKeys: ['0'],
        };
    },

    watch:{
      selectedKeys(val){
      },
    },

    components: { 
      // Icons
      DownloadOutlined, 
      SwitcherOutlined, 
      CompassOutlined, 
      DollarCircleOutlined, 
      AppstoreOutlined, 
      ExperimentOutlined,
      FrownOutlined,
      FunctionOutlined,
      UserOutlined,
      HomeOutlined,
      AreaChartOutlined,
      RiseOutlined,
      PieChartOutlined,
      StockOutlined,
      HeartOutlined,
      LikeOutlined,
      DatabaseOutlined,
      DashboardOutlined,

      // Pages
      HomePage,
      InstallsPage,
      VersionPage,
      GeographicPage,
      ProPage,
      OptionalProductPage,
      AiPage,
      UninstallSurveyPage,
      KronocardActivityPage,
      UserInfoPage,
      BusinessHubPage,
      FinancialHubPage,
      WebStatisticsPage,
      SocialNetworkPage,
      CardsInDatabasePage,
      RevenuesPage
    },

    mounted(){
        
        // redirect user to the login page if the sessionid and csrtoken doesn't exist
        if(this.$getCookie('sessionid') === null
            && this.$getCookie('csrftoken') === null){
            this.$router.push('/');
        }else{
        }
        
    },

    methods: {
    onCollapse(collapsed, type) {
    },
    onBreakpoint(broken) {
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');
#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  margin-bottom: 40pt;
}

#logo-text{
    color: #f68205;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15pt;
}
</style>