<template>
  <div class="BusinessHub">
    <!-- First row: Marketplace Activities -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Marketplace Activities</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- Online now -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Online Now"
            :value="mktOnlineNow"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Session This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Session This Month"
            :value="mktSessThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Session Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Session Last Month"
            :value="mktSessLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Session Time -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Session Time"
            :value="mktSessTime"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>

    <!-- Second row: Registered Users -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Marketplace Activities</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- Total Users -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Total Users"
            :value="rgsTotalUsers"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="rgsThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="rgsLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="rgsThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="rgsLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>

    <!-- Third row: User Stores -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>User Stores</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- Total Users -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Total Users"
            :value="utTotalUsers"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="utThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="utLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- This Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Week"
            :value="utThisWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Week -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Week"
            :value="utLastWeek"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>

    <!-- Forth row: Cards in Database -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Cards in Database</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="This Month"
            :value="cdThisMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last Month"
            :value="cdLastMonth"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last 7 Days -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Last 7 Days"
            :value="cdLast7Days"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Today -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Today"
            :value="cdToday"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- For Sale -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="For Sale"
            :value="cdForSale"
          >
          </a-statistic>
        </a-card>
      </a-col>  
    </a-row>

    <!-- Fifth row: Social Network -->
    <a-row><a-col :span='this.titleColSpan' class="dashboard-row-title"><h1>Social Networks</h1></a-col></a-row>
    <a-row :gutter="16">
      <!-- This Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Facebook Likes"
            :value="snFbLikes"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last Month -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Twitter Likes"
            :value="snTtLikes"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <!-- Last 7 Days -->
      <a-col :span="4">
        <a-card>
          <a-statistic
            title="Youtube Views"
            :value="snYtbViews"
          >
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
export default {
  name:"BusinessHubPage",
  components:{
  },
  setup(){
    const getMonday = () => {
      let d = new Date();
      let day = d.getDay();
      let diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    }
    return {
      getMonday
    }
  },
  data(){
    return {
      titleColSpan: 3,

      // Marketplace Activities
      mktOnlineNow: 0,
      mktSessThisMonth: 0,
      mktSessLastMonth: 0,
      mktSessTime: 0,

      // Registered Users
      rgsTotalUsers: 0,
      rgsThisMonth: 0,
      rgsLastMonth: 0,
      rgsThisWeek: 0,
      rgsLastWeek: 0,

      // User Stores
      utTotalUsers: 0,
      utThisMonth: 0,
      utLastMonth: 0,
      utThisWeek: 0,
      utLastWeek: 0,

      // Cards in Database
      cdThisMonth: 0,
      cdLastMonth: 0,
      cdLast7Days: 0,
      cdToday: 0,
      cdForSale: 0,

      // Social Networks
      snFbLikes: 0,
      snTtLikes: 0,
      snYtbViews: 0
    }
  },

  mounted(){
    // cdThisMonth: 0,
    // this.$get(`/api/dashboard/total-cards-in-database?from=${date1.toJSON()}&to=${date2.toJSON()}`)
    // cdLastMonth: 0,
    // cdLast7Days: 0,
    // cdToday: 0,
    // cdForSale: 0,
    let now = new Date();
    let bigBang = new Date(0,0,1);
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let firstDayOfCurrentWeek = this.getMonday();
    let firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth()-1, 1);
    
    this.$get(`/api/dashboard/total-cards-in-database?from=${today.toJSON()}&to=${now.toJSON()}`)
    .then((res) => {
      let data = res.data.total_cards;
      this.cdToday = data['to'] - data['from'];
    });

    this.$get(`/api/dashboard/total-cards-in-database?from=${firstDayOfCurrentMonth.toJSON()}&to=${now.toJSON()}`)
    .then((res) => {
      let data = res.data.total_cards;
      this.cdThisMonth = data['to'] - data['from'];
    });

    this.$get(`/api/dashboard/total-cards-in-database?from=${firstDayOfLastMonth.toJSON()}&to=${firstDayOfCurrentMonth.toJSON()}`)
    .then((res) => {
      let data = res.data.total_cards;
      this.cdLastMonth = data['to'] - data['from'];
    });

    this.$get(`/api/dashboard/total-cards-in-database?from=${firstDayOfCurrentWeek.toJSON()}&to=${now.toJSON()}`)
    .then((res) => {
      let data = res.data.total_cards;
      this.cdLast7Days = data['to'] - data['from'];
    });

    this.$get(`/api/dashboard/total-cards-in-database?from=${bigBang.toJSON()}&to=${now.toJSON()}`)
    .then((res) => {
      let data = res.data.for_sale_qty;
      this.cdForSale = parseInt(data['to']);
    });
  },

  
}
</script>

<style>
.dashboard-row-title{
  margin-top: 10px;
  text-align: start;
}
</style>
