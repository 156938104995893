<template>
  <div class="cards-in-database">
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom">
          
      <a-col :span="20">
        <a-card>
          <a-row justify="center" align="bottom">
            <a-col :span="12" style="background:#fff">
              <a-descriptions bordered size="small">
                <a-descriptions-item label="From" :span="1">{{ timeRange[0] ? new Date(timeRange[0]).toLocaleDateString() : "-" }} </a-descriptions-item>
                <a-descriptions-item label="To" :span="1">{{ timeRange[1] ? new Date(timeRange[1]).toLocaleDateString(): "-" }}</a-descriptions-item>
              </a-descriptions>
            </a-col>
            <a-col :span="6">
              <date-picker
                v-model = "timeRange"
                @time-selected="timeRangeChange"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <div>
      <a-empty v-if="isEmpty"/>
      <div v-else id="chart">
              <div id="cards-in-db-chart"/>
                <multi-line-chart
                  :dataSource="this.dataSource"
                  :types="types"
                  :groupBy="groupBy"
                  xField="time"
                  :yField="yField"
                  :empty="isEmpty"
                  :height="400"
                  id="cards-in-db-chart"
                />
            </div>
    </div>
    <a-table
      style="margin-top:20px"
      :columns="columns" :data-source="dataSourceTable" bordered>
    </a-table>
  </div>
</template>

<script>
import { ref, onMounted, watch, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import DatePicker from '../../components/time_selector/DatePicker.vue';
import MultiLineChart from '../../components/charts/MultiLineChart.vue'
export default {
  name:"CardsInDatabasePage",
  components:{
    MultiLineChart,
    DatePicker
  },

  setup(props, context){
    const display = ref("amount");

    const dataSource = ref([{category: "Basketball", time: "2021-06-06T00:00:00.000Z", value: 13, total_qty: 363}]);
    const dataSourceTable = ref([]);
    const types = ref(['Other',
              'Magic',
              'Pokemon',
              'Soccer',
              'Hockey',
              'Basketball',
              'Football',
              'Baseball'
            ]);
    const groupBy = ref('category');
    const xField = ref('time');
    const yField = ref("total_qty");
    const isEmpty = ref(true);
    
    /* time range related */
    const timeRange = ref([]);
    
    //ref([new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toString(), new Date().toString()]);

    const buildRequest = () => {
      let date1 = new Date(timeRange.value[0]);
      let date2 = new Date(timeRange.value[1]);
      let request = `/api/dashboard/cards-in-database?from=${date1.toJSON()}&to=${date2.toJSON()}`;
      return request;
    };

    const buildRequestTable = () => {
      let date1 = new Date(timeRange.value[0]);
      let date2 = new Date(timeRange.value[1]);
      let request = `/api/dashboard/total-cards-in-database?from=${date1.toJSON()}&to=${date2.toJSON()}`;
      return request;
    }

    const { ctx }  = getCurrentInstance()

    const fetchCardsTable = () => {
      let url = buildRequestTable();
      ctx._.appContext.config.globalProperties.$get(url)
        .then(res => {
          let tableData = [];
          let fields = ['total_qty', 'for_sale_qty', 'total_value', 'sold_qty', 'sold_value']
          let total = {
            'category': "Total",
            'total_qty': 0,
            'for_sale_qty': 0,
            'total_value': 0,
            'sold_qty': 0,
            'sold_value': 0
          }
          for(let category of types.value){
            tableData.push(res.data['data'][category]['to']);
            for (let field of fields){
              total[field] += res.data['data'][category]['to'][field];
            }
          }
          tableData.push(total);
          dataSourceTable.value = tableData;
        }).catch(e => {
        });
    }

    const fetchCardsChart = () => {
      let request = buildRequest();
      ctx._.appContext.config.globalProperties.$get(request)
        .then(res => {
          isEmpty.value = false;
          dataSource.value = res.data;
          types.value = ['Other',
            'Magic',
            'Pokemon',
            'Soccer',
            'Hockey',
            'Basketball',
            'Football',
            'Baseball'
          ];
        }).catch(e => {
          isEmpty.value = true;
        });
    }

    const timeRangeChange = (value) => {
      timeRange.value = value;
      fetchCardsTable();
      fetchCardsChart();
    }

    const columns = [
      {
        title: "Card Type",
        dataIndex: "category"
      },
      {
        title: "Total Qty",
        dataIndex: "total_qty"
      },
      {
        title: "For Sale",
        dataIndex: "for_sale_qty"
      },
      {
        title: "Total $",
        dataIndex: "total_value"
      },
      {
        title: "Sold Qty",
        dataIndex: "sold_qty"
      },
      {
        title: "Sold $",
        dataIndex: "sold_value"
      },
    ];

    return {
      dataSource, 
      xField, yField,
      groupBy, types, 
      isEmpty, timeRange, 
      timeRangeChange, 
      buildRequest,  
      display,
      columns, dataSourceTable,
      fetchCardsTable
    }
  },

  mounted(){
    
    this.$get('/api/last/?time-field=time&query=from dashboard_kronocardstat')
      .then(res => {
        this.timeRange[1] = new Date(new Date(res.data.time).getTime() + 60000).toString();
        this.timeRange[0] = new Date(new Date(res.data.time).getTime() - (30 * 24 * 60 * 60 * 1000)).toString()
        this.fetchCardsTable();
        var request = this.buildRequest();
        // send reqeust for ai data
        this.$get(request)
          .then(res => {
            this.isEmpty = false;
            this.dataSource = res.data;
            this.types =  ['Other',
              'Magic',
              'Pokemon',
              'Soccer',
              'Hockey',
              'Basketball',
              'Football',
              'Baseball'
            ];
          })
          .catch(e => {
            this.isEmpty = true;
          });  
      })
      .catch(e => {
        this.isEmpty = true;
      });   
  }

}
</script>

