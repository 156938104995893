<template>
  <div class="revenue-page">
    Revenue Page
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
export default {
  name:"RevenuesPage",
  components:{
  },

  setup(props, context){
    

    return { }
  },

}
</script>

