<template>
  <div class="UserInformation">
    
    <a-row v-if="isEmpty" style="width:100%; margin-top:15pt" justify="center">
      <a-col :span="20" >
        <a-card>
          <a-spin tip="Loading...">
            <a-empty />
          </a-spin>
        </a-card>
      </a-col>
    </a-row>
    
    <a-row v-else style="width:100%; margin-top:15pt" justify="center">
      
      <a-col :span="20" >
        <a-card>
          <a-table :columns="this.columns" 
            :data-source="this.dataSource" 
            :scroll="{ x: 1500, y: 600 }"
          > 
            <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
              <div style="padding: 8px">
                <a-input
                  ref="searchInput"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                  <template #icon><SearchOutlined /></template>
                  Search
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                  Reset
                </a-button>
              </div>
            </template>
            <template #filterIcon="filtered">
              <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <template #customRender="{ text, column }">
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    class="highlight"
                    :key="i"
                  >
                    {{ fragment }}
                  </mark>
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>

            <template #store= "{ record }">
              <a :href="record.store_url"> {{ record.store_name }} </a>
            </template>

            <template #action= "{ record }" >
              <a @click="showModal({ record })">more</a>
            </template>
          </a-table>
        </a-card>
      </a-col>

      <a-modal v-model:visible="visible" title="Basic Modal" @ok="handleOk">
        <a-descriptions title="User Info" bordered>
          <a-descriptions-item :span="3" label="UserName">{{ this.selectedUser.username }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Email">{{ this.selectedUser.email }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="store"> <a :href="this.selectedUser.store_url" target="_blank">{{ this.selectedUser.store_name }}</a> </a-descriptions-item>
          <a-descriptions-item :span="3" label="Location">{{ `${this.selectedUser.location.city}, ${this.selectedUser.location.region}, ${this.selectedUser.location.country},` }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Language">{{ this.selectedUser.language }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="OS">{{ this.selectedUser.os }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Cards sent to AI">{{ this.selectedUser.total_cards_ai }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Cards sold Kronozio">{{ this.selectedUser.total_cards_sold_kronozio }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Cards sold eBay">{{ this.selectedUser.total_cards_sold_ebay }}</a-descriptions-item>
          <a-descriptions-item :span="3" label="Last activity">{{ this.selectedUser.last_activity.type }} @ {{ this.selectedUser.last_activity.time }}</a-descriptions-item>
          
        </a-descriptions>
      </a-modal>
    
    </a-row>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, provide, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
export default {
  name:"UserInfoPage",
  components:{
    SearchOutlined
  },

  setup(props, context){
    const { ctx }  = getCurrentInstance()

    // modal related
    const visible = ref(false);
    const selectedUser = ref({});

    const dataSource = ref([]);
    const columns = [
      {title: "Username", dataIndex: "username", key: "username", fixed: 'left',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender',
        },
        onFilter: (value, record) =>
          record.username.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 0);
          }
        },
      },
      {title: "Email", dataIndex: "email", key: "email"},
      {title: "Pro", dataIndex: "pro", key: "pro"},
      {title: "Store", dataIndex:"store_name",
        slots: {
          customRender: 'store'
        },
      },
      {title: "Cards Sent to AI", dataIndex: "total_cards_ai", key:"total_cards_ai"},
      {title: "Cards Sold on eBay", dataIndex: "total_cards_sold_ebay", key:"total_cards_sold_ebay"},
      {title: "Cards Sold on Kronozio", dataIndex: "total_cards_sold_kronozio", key:"total_cards_sold_kronozio"},
      {title: "Last Activity", dataIndex: "last_activity.type", key:"last_activity.type"},
      {title: "Last Activity TIme", dataIndex: "last_activity.time", key:"last_activity.time"},
      { title: 'Action', key: 'operation', fixed: 'right',
        slots: {
          customRender: 'action',
        },
      },
    ];
    const isEmpty = ref(true);
    provide('isEmpty', isEmpty);

    const fetchUserInfo = () => {
      ctx._.appContext.config.globalProperties.$get("/api/user/table/")
        .then(res => {
          dataSource.value = res.data;
          isEmpty.value = false;
        }).catch(err => {
          dataSource.value = [];
          isEmpty.value = true;
        })
    };
    onMounted(fetchUserInfo);


    const state = reactive({
      searchText: '',
      searchedColumn: '',
    });

    const searchInput = ref();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = clearFilters => {
      clearFilters();
      state.searchText = '';
    };

    const showModal = ({record}) => {
      console.log("showing modal")
      visible.value = true;
      selectedUser.value = record;
    }

    const handleOk = e => {
      visible.value = false;
    };

    return {dataSource, columns, state, handleSearch, handleReset,
      searchText: '',
      searchInput,
      searchedColumn: '',
      

      // modal related
      visible,
      selectedUser,
      showModal,
      handleOk,
      isEmpty
    }
  },

}
</script>

