<template>
  <div id="multi-line-container"></div>
    
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUpdated, onBeforeUnmount } from 'vue'
import { Area, Line } from '@antv/g2plot';
//import { onMounted, onUpdated } from '@vue/runtime-core';
export default defineComponent({
  name: "MultiLineChart",
  props:{
    dataSource: Array,
    groupBy: String,
    xField: String,
    yField: String,
    types: Array,
    rangeMin: Number,
    rangeMax: Number,
    height: Number,
    id: String
  },

  // entry point of the component
  setup(props){
    // initialize the lineChart ref
    const chart = ref('');

    // create the chart object
    const createChart = () => {
      let counter = 0;
      let pointsPerDraw = Math.max(1, Math.floor(props.dataSource.length / 60));
      const COLOR_PLATE_10 = ["#ff7f11","#2e86ab","#909198","#a40e4c","#2c2c54","#ffd23f","#d999b9","#1f271b","#06d6a0","#dacc3e"];
      let options = {
        data: props.dataSource,
        height: props.height ? props.height : 600,
        xField: props.xField,
        yField: props.yField,
        colorField: props.groupBy ? props.groupBy : "",
        seriesField: props.groupBy ? props.groupBy : "",
        yAxis: {
          label: {
            //formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },

        xAxis: {
          label: {
            formatter: (v) => new Date(v).toLocaleString()
          }
        },

        tooltip: {
          showTitle: true,
          title: "time",
          formatter: (datum) => {
            return { name: props.groupBy == "" ? "value" : datum[props.groupBy], value: datum[props.yField]};
          },
        },

        color: (item) => {
          let category = item[props.groupBy];
          return COLOR_PLATE_10[props.types.indexOf(category)];
        },

        //color: COLOR_PLATE_10,
        isStack: true,
        point: {
          style: ({time}) => {
            return {
              r: counter++ % pointsPerDraw ? 0 : 3, // only display 60 points
            };
          },
        },
      }
      return options
    }

    // render or re-render the chart
    const renderChart = () => {
        chart.value.destroy();
        let options = createChart();
        chart.value = new Area(props.id, options);
        chart.value.render();
    };

    onMounted(() => {
      
        let options = createChart();
        chart.value = new Area(props.id, options);
        chart.value.render();
    });
    onUpdated(renderChart);

    onBeforeUnmount(() => {
        chart.value.destroy();
    });

  }
})
</script>

<style>

</style>
