<template>
    <a-range-picker
      :show-time="{ format: 'HH' }"
      format="YYYY-MM-DD"
      :placeholder="['Start Time', 'End Time']"
      @change="onChange"
      @ok="onOk"
    >
        <a-button
            type="primary"
        >
            Custom Range
        </a-button>
    </a-range-picker>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "date-picker",

    setup(props, context) {
        const onChange = (value, dateString) => {
        };

        const onOk = value => {
            context.emit("time-selected", value);
        };

        return {
            onChange,
            onOk,
        };
    },
})

</script>

<style>

</style>