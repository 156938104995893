<template>
  <div class="home" >
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom">
        <a-col :span="20">
            <h1 style="color:#f68205; font-size:30pt; font-weight:bold">
                Live Matrix
            </h1>
        </a-col>
    </a-row>
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom">
        <a-col :span="20">
            <a-row  style="margin-top:40pt" justify="space-between" :gutter="[16,16]">
                
                <!-- groups to document -->
                <a-col :span="8">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Groups to Document"
                            :value="dataSource.groups_to_document"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

                <!-- cards to document -->
                <a-col :span="8">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Cards to Document"
                            :value="dataSource.cards_to_document"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

                <!-- Processing -->
                <a-col :span="8">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Processing"
                            :value="dataSource.transit_to_client"
                            suffix="Cards/min"
                            :precision="1"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>
            </a-row>
            <br/>
            <!-- second row -->
            <a-row style="margin-top:15pt" justify="space-around" :gutter="[16,16]">
                <!-- request last 1 -->
                <a-col :span="6">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Last 24 hours"
                            :value="dataSource.request_last_1"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

                <!-- request last 7 -->
                <a-col :span="6">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Last 7 days"
                            :value="dataSource.request_last_7"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

                <!-- request last 30 -->
                <a-col :span="6">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Last 30 days"
                            :value="dataSource.request_last_30"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

                <!-- request last 30 -->
                <a-col :span="6">
                    <a-card style="height:150px">
                        <a-statistic
                            title="Total"
                            :value="dataSource.request_total"
                            :value-style="{ color: '#f68205', 'font-size': '40px' }"
                        />
                    </a-card>
                </a-col>

            </a-row>
        </a-col>
    </a-row>
  </div>
  
</template>

<script>
import { reactive, ref } from 'vue';

export default {
  name:"HomePage",
  components:{
  },

  setup(props, context){
    const dataSource = ref({});

    const path = `ws://${window.location.host}/ws/summary-data`;
    const socket = new WebSocket(path);

    // assign onMsg as the callback of the socket.onmessage
    const onMsg = (msg) => {
        dataSource.value = JSON.parse(msg.data);
    };
    socket.onmessage = onMsg;

    return {dataSource}
  },
  
}
</script>

<style>
.installs{
  margin-top: 20pt;
}

</style>
