<template>
    <mix-chart
      :dataSource="this.dataSource"
      :groupBy="groupBy"
      :xField="xField"
      :yField="yField"
    />
</template>

<style>
#card-body .ant-card-bordered{
    border-radius: 10px !important;
}
</style>

<script>
import MultiLineChart from '../components/charts/MultiLineChart.vue';
import DualAxesChart from '../components/charts/DualAxesChart.vue';
import MixChart from '../components/charts/MixPieAreaChart.vue';

import {defineComponent ,ref} from 'vue';
export default defineComponent({
  name: "Home", 
  components: {
    MultiLineChart,
    DualAxesChart,
    MixChart,
  },
  setup(){
    const dataSource = ref([]);
    const groupBy = ref(['type', 'type', 'type'])
    const xField = ref(['time', 'time', 'time'])
    const yField = ref(['value', 'cumcount', 'value'])
    return {dataSource, xField, yField, groupBy}
  },
  mounted(){
    this.$get("/api/kronocard/all/linear/?tick-interval=12H&")
      .then(res => {
        this.dataSource = res.data.data; 
      });
    
  }
})
</script>