<template>
  <div class="ai">
    
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom">
          
            <a-col :span="20">
              <a-card>
                <a-row justify="start" align="bottom">
                  <a-col :span="12" style="background:#fff">
                    <a-descriptions bordered size="small">
                      <a-descriptions-item label="From" :span="1">{{ timeRange[0] ? new Date(timeRange[0]).toLocaleDateString() : "-" }} </a-descriptions-item>
                      <a-descriptions-item label="To" :span="1">{{ timeRange[1] ? new Date(timeRange[1]).toLocaleDateString(): "-" }}</a-descriptions-item>
                    </a-descriptions>
                  </a-col>

                  <a-col :span="6" />

                  <a-col :span="4">
                    <date-picker
                      v-model = "timeRange"
                      @time-selected="timeRangeChange"
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          
        

    </a-row>
    
    <a-row v-if="isEmpty" style="width:100%; margin-top:15pt" justify="center">
      <a-col :span="20" >
        <a-card>
          <a-empty />
        </a-card>
      </a-col>
    </a-row>
    
    <a-row v-else style="width:100%; margin-top:15pt" justify="center">
      
      <a-col :span="20" >
        <a-card>
          <div id="chart">
            <mix-chart
              :dataSource="this.dataSource"
              :types="types"
              :groupBy="groupBy"
              :xField="xField"
              :yField="yField"
              :empty="isEmpty"
            />
          </div>
        </a-card>
      </a-col>
    
    </a-row>

    <a-row v-if="!isEmpty" style="width:100%; margin-top:15pt" justify="center">
      
      <a-col :span="20" >
        <a-card>
          <div id="bar-chart">
            <bar-chart
              :dataSource="this.dataSourceUser"
              :types="types"
              :groupBy="groupBy"
              xField="submitted"
              yField="username"
              :empty="isEmpty"
            />
          </div>
        </a-card>
      </a-col>
    
    </a-row>



    
  </div>
  
</template>

<script>
import MixChart from '../../components/charts/MixPieAreaChart.vue';
import DatePicker from '../../components/time_selector/DatePicker.vue';
import BarChart from '../../components/charts/BarChart.vue';

import { ref, onMounted, getCurrentInstance, provide } from 'vue';

export default {
  name:"AIPage",
  components:{
    MixChart,
    DatePicker,
    BarChart
  },

  setup(props, context){
    const dataSourceUser = ref([
      {index: 0, username: "mallory3373", os: "Windows 8.1", language: "Unknown", count: 886}
    ]);

    const dataSource = ref({
        line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
        pie: [{type: "Install", value: 549, time: 549}]
    });
    const types = ref(["Install", "Upgrade", "Uninstall"]);
    const groupBy = ref(['type', 'type', 'type']);
    const xField = ref(['time', 'time', 'time']);
    const yField = ref(['value', 'cumcount', 'value']);
    const isEmpty = ref(true);
    provide('isEmpty', isEmpty);
    
    /* time range related */
    const timeRange = ref([new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toString(), new Date().toString()]);
    const buildRequests = () => {
      let date1 = new Date(timeRange.value[0]);
      let date2 = new Date(timeRange.value[1]);
      let interval = (Math.floor((date2.getTime() - date1.getTime()) / 60 / 1000)).toString() + "S";
      let request1 = `/api/ai/identified/linear/?from=${date1.toJSON()}&to=${date2.toJSON()}&tick-interval=${interval}`
      let request2 = `/api/ai/rank/?from=${date1.toJSON()}&to=${date2.toJSON()}`
      return [request1, request2];
    };
    const { ctx }  = getCurrentInstance()

    const timeRangeChange = (value) => {
      timeRange.value = value;
      var requests = buildRequests();
      ctx._.appContext.config.globalProperties.$get(requests[0])
        .then(res => {
          isEmpty.value = false;
          dataSource.value = res.data.data;
          types.value = res.data.types;
        }).catch(e => {
          isEmpty.value = true;
          dataSource.value = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
          };
          types.value = ["Install", "Upgrade", "Uninstall"];
        });
      
      // fetch user ranking data
      ctx._.appContext.config.globalProperties.$get(requests[1])
        .then(res => {
          dataSourceUser.value = res.data;
        })
        .catch(e => {
        });

    }


    return {dataSourceUser, dataSource, xField, yField, groupBy, types, isEmpty, timeRange, timeRangeChange, buildRequests}
  },

  mounted(){
    
    this.$get('/api/last/?time-field=time&query=from activity_kronocardactivity where type=7')
      .then(res => {
        this.timeRange[1] = new Date(new Date(res.data.time).getTime() + 60000).toString();
        this.timeRange[0] = new Date(new Date(res.data.time).getTime() - (14 * 24 * 60 * 60 * 1000)).toString()
        var requests = this.buildRequests();
        // send reqeust for ai data
        this.$get(requests[0])
          .then(res => {
            this.isEmpty = false;
            this.dataSource = res.data.data;
            this.types = res.data.types;
          })
          .catch(e => {
            this.isEmpty = true;
            this.dataSource = {
                line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
                pie: [{type: "Install", value: 549, time: 549}]
            };
            this.types = ["Install", "Upgrade", "Uninstall"];
          });
        
        // fetch user ranking data
        this.$get(requests[1])
          .then(res => {
            this.isEmpty = false;
            this.dataSourceUser = res.data;
          })
          .catch(e => {
            this.dataSourceUser = {
                line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
                pie: [{type: "Install", value: 549, time: 549}]
            };
            this.types = ["Install", "Upgrade", "Uninstall"];
            console.log(this.dataSourceUser);
          });
      })
      .catch(e => {
        this.isEmpty = true;
        this.dataSource = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
        };
        this.types = ["Install", "Upgrade", "Uninstall"];
        console.log(e);
      });

    
  }
  
}
</script>

<style>
.installs{
  margin-top: 20pt;
}

</style>