<template>
  <div id='dual-axes-container'></div>
    
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUpdated, onBeforeUnmount} from 'vue'
import { DualAxes } from '@antv/g2plot';

//import { onMounted, onUpdated } from '@vue/runtime-core';
export default defineComponent({
  name: "DualAxesChart",
  props:{
    dataSource: Array,
    groupBy: Array,
    xField: String,
    yField: Array,
    types: Array,
    height: Number,
    chartID: String,
    display: String,
  },

  // entry point of the component
  setup(props){
    // initialize the lineChart ref
    const chartID = props.chartID;
    const chart = ref('');
    const COLOR_PLATE_10 = ["#ff7f11","#2e86ab","#909198","#a40e4c","#2c2c54","#ffd23f","#d999b9","#1f271b","#06d6a0","#dacc3e"];
    // create the chart object
    const createChart = () => {
      let counter = 0;
      let pointsPerDraw = Math.floor(props.dataSource.length / 60);
      let options = {
        data: [props.dataSource, props.dataSource],
        height: props.height ? props.height : 600,
        xField: props.xField,
        yField: props.yField,
        xAxis: {
          label: {
            formatter: (v) => new Date(v).toLocaleString()
          }
        },
        yAxis:{},
        
        tooltip: {
          formatter: (datum) => {
            let ks = Object.keys(datum)
            ks = ks.filter(key => key != "time" && key != props.groupBy[0])
            return { name: datum[props.groupBy[0]] + ' - ' +ks[0], 
                     value: props.display == "amount" ? "$"+ datum[ks[0]] : datum[ks[0]]};
          },
        },

        geometryOptions: [
            {
                geometry: 'line',
                seriesField: props.groupBy[0],
                colorField: props.groupBy[0],
                color: ({ type }) => {
                  return COLOR_PLATE_10[props.types.indexOf(type)]
                },
                lineStyle:{
                  lineWidth: 3,
                },
                point: {
                    style: ({time}) => {
                        return {
                            r: counter++ % pointsPerDraw ? 0 : 3, // only display 60 points
                        };
                    },
                },
                
            },
            {
                geometry: 'line',
                seriesField: props.groupBy[1],
                colorField: props.groupBy[1],
                color: ({ type }) => {
                  return COLOR_PLATE_10[props.types.indexOf(type)]
                },
                lineStyle:{
                  lineWidth: 1,
                },
            },
        ],
      }

      if(props.display == "amount"){
        for (var i in props.yField){
          options.yAxis[props.yField[i]] = {
            label: {
              formatter: (text, item, index) => {
                return props.display == "amount" ? "$"+ text : text;
              }
            }
          }
        }
      }
      return options
      //return new DualAxes('dual-axes-container', options);
    }
    
    // render or re-render the chart
    const renderChart = () => {
        let options = createChart();
        chart.value.update(options);
    };

    onMounted(() => {
        let options = createChart();
        chart.value = new DualAxes('dual-axes-container', options);
        chart.value.render();
    });

    onUpdated(renderChart);

    onBeforeUnmount(() => {
        chart.value.destroy();
    });

    return {chartID};
  }
})
</script>

<style>

</style>
