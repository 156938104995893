<template>
  <div class="card-scan-chart">
    <a-row style="width:100%; margin-top:15pt" justify="center">
      <a-col :span="20">
        <a-card >
            <a-row style="width:100%" justify="end">
              <a-col :span="10">
                <span>Cards Scanned</span>
              </a-col>

              <!-- radio group -->            
              <a-col :span="14">
                <a-radio-group v-model:value="yField" button-style="solid" @change="onChangeRadio">
                    <a-radio-button value="count">Quantity</a-radio-button>
                    <a-radio-button value="cumcount">Cumulative Quantity</a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
            <a-empty v-if="isEmpty"/>
            <div v-else id="chart">
              <div id="card-scan-chart"/>
                <multi-line-chart
                  :dataSource="this.dataSourceCardScanned.line"
                  :types="types"
                  :groupBy="groupBy"
                  xField="time"
                  :yField="yField"
                  :empty="isEmpty"
                  :height="500"
                  id="card-scan-chart"
                />
            </div>
        </a-card>
      </a-col>
    
    </a-row>
  </div>
  
</template>

<script>
import MultiLineChart from '../charts/MultiLineChart.vue';
import DatePicker from '../time_selector/DatePicker.vue';

import { ref, onMounted, onUpdated, onBeforeUnmount, getCurrentInstance, provide, inject, watch } from 'vue';

export default {
  name:"CardScanChart",
  components:{
    DatePicker,
    MultiLineChart
  },

  setup(props, context){
    const display = ref("amount");

    const dataSourceCardScanned = ref([
      {index: 0, username: "mallory3373", os: "Windows 8.1", language: "Unknown", count: 886}
    ]);
    const types = ref(["Install", "Upgrade", "Uninstall"]);
    const groupBy = ref('type');
    const xField = ref('time');
    const yField = ref("cumcount");
    const isEmpty = ref(true);
    
    /* time range related */
    const timeRange = inject("timeRange") 
    
    //ref([new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toString(), new Date().toString()]);

    const buildRequest = () => {
      let date1 = new Date(timeRange.value[0]);
      let date2 = new Date(timeRange.value[1]);
      let interval = (Math.floor((date2.getTime() - date1.getTime()) / 60 / 1000)).toString() + "S";
      let request = `/api/card/scan/linear/?from=${date1.toJSON()}&to=${date2.toJSON()}&tick-interval=${interval}`

      return request;
    };


    const { ctx }  = getCurrentInstance()

    const timeRangeChange = (value) => {
      var request = buildRequest();
      ctx._.appContext.config.globalProperties.$get(request)
        .then(res => {
          isEmpty.value = false;
          dataSourceCardScanned.value = res.data.data;
          types.value = res.data.types;
        }).catch(e => {
          isEmpty.value = true;
          dataSourceCardScanned.value = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
          };
          types.value = ["Install", "Upgrade", "Uninstall"];
        });
    }

    watch(timeRange, (newVal, oldVal) => {
        timeRangeChange(newVal);
    },{
      deep: true
    });

    const onChangeRadio = (val) => {
       
    }

    return {dataSourceCardScanned, xField, yField, groupBy, types, isEmpty, timeRange, timeRangeChange, buildRequest, onChangeRadio, display}
  },

  mounted(){
    
    this.$get('/api/last/?time-field=time&query=from activity_kronocardactivity where type >= 2 and type <= 3')
      .then(res => {
        this.timeRange[1] = new Date(new Date(res.data.time).getTime() + 60000).toString();
        this.timeRange[0] = new Date(new Date(res.data.time).getTime() - (14 * 24 * 60 * 60 * 1000)).toString()
        var request = this.buildRequest();
        // send reqeust for ai data
        this.$get(request)
          .then(res => {
            this.isEmpty = false;
            this.dataSourceCardScanned = res.data.data;
            this.types = res.data.types;
          })
          .catch(e => {
            this.isEmpty = true;
            this.dataSourceCardScanned = {
                line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
                pie: [{type: "Install", value: 549, time: 549}]
            };
            this.types = ["Install", "Upgrade", "Uninstall"];
          });
        
        
      })
      .catch(e => {
        this.isEmpty = true;
        this.dataSource = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
        };
        this.types = ["Install", "Upgrade", "Uninstall"];
        console.log(e);
      });

    
  }
  
}
</script>

<style>
.installs{
  margin-top: 20pt;
}

</style>