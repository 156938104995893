<template>
    <div>
        <a-row justify="end">
            
            <a-col :span="2">
                <a-switch 
                    @change="onChangeSwitch"
                />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <div id="mix-container"></div>
            </a-col>
        </a-row>
    </div>
    
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUpdated, onBeforeUnmount, inject } from 'vue'
import { Mix, G2 } from '@antv/g2plot';

//import { onMounted, onUpdated } from '@vue/runtime-core';
export default defineComponent({
  name: "MixBarAreaChart",
  props:{
    dataSource: Array,
    groupBy: Array,
    xField: Array,
    yField: Array,
    types: Array,
    height: Number,
  },

  // entry point of the component
  setup(props){
    const isStack = ref(true);
    // Register interactions
    G2.registerInteraction('custom-association-filter', {
        showEnable: [
            { trigger: 'element:mouseenter', action: 'cursor:pointer' },
            { trigger: 'element:mouseleave', action: 'cursor:default' },
        ],
        start: [
            {
                trigger: 'element:click',
                action: (context) => {
                    const { view, event } = context;
                
                    // get and update the second view
                    const view1 = view.parent.views[1];
                    view1.filter(props.groupBy[1], (d) => d === event.data?.data[props.groupBy[1]]);
                    view1.render(true);

                },
            },
        ],
        end: [
            {
                trigger: 'element:dblclick',
                action: (context) => {
                    const { view } = context;

                    // get and update the second view
                    const view1 = view.parent.views[1];
                    view1.filter(props.groupBy[1], null);
                    view1.render(true);

                },
            },
        ],
    });
    const isEmpty = inject("isEmpty");

    // initialize the lineChart ref
    const chart = ref('');
    const showPoints = ref(false);

    // create the chart object
    const createChart = () => {
        let counter = 0;
        let pointsPerDraw = Math.floor(props.dataSource.length / 60);
        let options = {
            tooltip: false,
            height: props.height ? props.height : 600,
            syncViewPadding: true,
        }

        const COLOR_PLATE_10 = ["#ff7f11","#2e86ab","#909198","#a40e4c","#2c2c54","#ffd23f","#d999b9","#1f271b","#06d6a0","#dacc3e"];

        let plots = [
            {
                type: 'bar',
                region: { start: { x: 0, y: 0 }, end: { x: 1, y: 0.45 } },
                options: {
                    data: props.dataSource.pie,
                    color: COLOR_PLATE_10,
                    xField: props.xField[0],
                    yField: props.yField[0],
                    color: "#f68205",
                    yAxis: {
                        label: {
                            autoRotate: false,
                        },
                    },
                    scrollbar: {
                        type: 'vertical',
                    },
                    tooltip: {
                        showMarkers: false,
                    },
                    interactions: [{ type: 'element-highlight' }, { type: 'custom-association-filter' }]
                },
            },
            {
                type: 'area',
                region: { start: { x: 0, y: 0.5 }, end: { x: 1, y: 1 } },
                options: {
                    isStack: isStack.value,
                    connectNulls: true,
                    data: props.dataSource.line,
                    color: COLOR_PLATE_10,
                    xField: props.xField[1],
                    yField: props.yField[1],
                    seriesField: props.groupBy[1],
                    colorField: props.groupBy[1],
                    color: ({ type }) => {
                        return COLOR_PLATE_10[props.types.indexOf(type)]
                    },
                    xAxis: {
                        label: {
                            formatter: (v) => new Date(v).toLocaleString()
                        }
                    },
                    line: {},
                    point: { style: { r: 2.5 } },
                    meta: {
                        time: { range: [0, 1] },
                    },
                    smooth: true,
                    tooltip: {
                        showCrosshairs: true,
                        shared: true,
                    },
                },
            },
        ];

        options["plots"] = plots;
        return options;
    }

    // render or re-render the chart
    const renderChart = () => {
        let options = createChart();
        chart.value.update(options);
    };

    onMounted(() => {
        let options = createChart();
        chart.value = new Mix('mix-container', options);
        chart.value.render();
        
    });

    onUpdated(renderChart);

    onBeforeUnmount(() => {
        chart.value.destroy();
    });

    
    const onChangeSwitch = () => {
        isStack.value = !isStack.value;
        renderChart();
    }

    return {isEmpty, isStack, renderChart, onChangeSwitch};
  }
})
</script>

<style>

</style>
