<template>
  <div class="geographic">
    <a-row style="width:100%; margin-top:15pt" justify="center" align="bottom">
      <a-col :span="20">
        <a-card>
          <a-row justify="center" align="bottom">
            <a-col :span="12" style="background:#fff">
              <a-descriptions bordered size="small">
                <a-descriptions-item label="From" :span="1">{{ timeRange[0] ? new Date(timeRange[0]).toLocaleDateString() : "-" }} </a-descriptions-item>
                <a-descriptions-item label="To" :span="1">{{ timeRange[1] ? new Date(timeRange[1]).toLocaleDateString(): "-" }}</a-descriptions-item>
              </a-descriptions>
            </a-col>

            <a-col :span="6">
              <date-picker
                v-model = "timeRange"
                @time-selected="timeRangeChange"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    
    <a-row v-if="isEmpty" style="width:100%; margin-top:15pt" justify="center">
      <a-col :span="20" >
        <a-card>
          <a-empty />
        </a-card>
      </a-col>
    </a-row>
    
    <a-row v-else style="width:100%; margin-top:15pt" justify="center">
      
      <a-col :span="20" >
        <a-card>
          <div id="chart">
            <mix-chart-pie-bar
              :dataSource="this.dataSource"
              :types="types"
              :groupBy="groupBy"
              :xField="xField"
              :yField="yField"
              :empty="isEmpty"
            />
          </div>
        </a-card>
      </a-col>
    
    </a-row>
  </div>
</template>

<script>
import MixChartPieBar from '../../components/charts/MixPieBarChart.vue';
import DatePicker from '../../components/time_selector/DatePicker.vue';
import { ref, onMounted, getCurrentInstance, provide } from 'vue';

export default {
  name:"UninstallSurveyPage",
  components:{
    MixChartPieBar,
    DatePicker,
  },

  setup(props, context){
    const display = ref("type")
    const dataSource = ref({
        line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
        pie: [{type: "Install", value: 549, time: 549}]
    });

    const types = ref(["Install", "Upgrade", "Uninstall"]);
    const groupBy = ref([display, display]);
    const xField = ref(['value', 'time']);
    const yField = ref(['type', 'value']);
    const isEmpty = ref(true);
    provide('isEmpty', isEmpty);
    
    /* time range related */
    const timeRange = ref([new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000)).toString(), new Date().toString()]);
    const buildRequest = () => {
      let date1 = new Date(timeRange.value[0]);
      let date2 = new Date(timeRange.value[1]);
      let interval = (Math.floor((date2.getTime() - date1.getTime()) / 60 / 1000)).toString() + "S";
      let reqeust = `/api/kronocard/uninstall-survey/linear/?from=${date1.toJSON()}&to=${date2.toJSON()}&tick-interval=${interval}`
      return reqeust;
    };
    const { ctx }  = getCurrentInstance()

    const timeRangeChange = (value) => {
      timeRange.value = value;
      var request = buildRequest();
      ctx._.appContext.config.globalProperties.$get(request)
        .then(res => {
          isEmpty.value = false;
          dataSource.value = res.data.data;
          dataSource.value["pie"] = dataSource.value["pie"].sort((a,b) => b.value - a.value);
          types.value = res.data.types;
        }).catch(e => {
          console.log(e)
          isEmpty.value = true;
          dataSource.value = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
          };
          types.value = ["Install", "Upgrade", "Uninstall"];
          console.log(isEmpty.value)
        })

    }

    const onChangeRadio = (val)=>{
      var request = buildRequest();
      ctx._.appContext.config.globalProperties.$get(request)
        .then(res => {
          isEmpty.value = false;
          dataSource.value = res.data.data;
          dataSource.value["pie"] = dataSource.value["pie"].sort((a,b) => b.value - a.value);
          types.value = res.data.types;
        }).catch(e => {
          console.log(e)
          isEmpty.value = true;
          dataSource.value = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
          };
          types.value = ["Install", "Upgrade", "Uninstall"];
          console.log(isEmpty.value)
        })
    }

    return {dataSource, xField, yField, groupBy, types, isEmpty, timeRange, timeRangeChange, buildRequest, onChangeRadio}
  },

  mounted(){
    
    this.$get('/api/last/?time-field=time&query=from software_uninstallsurvey')
      .then(res => {
        this.timeRange[1] = new Date(new Date(res.data.time).getTime() + 60000).toString();
        this.timeRange[0] = new Date(new Date(res.data.time).getTime() - (14 * 24 * 60 * 60 * 1000)).toString()
        console.log(this.timeRange);
        var request = this.buildRequest();
        this.$get(request)
          .then(res => {
            this.isEmpty = false;
            this.dataSource = res.data.data;
            this.dataSource["pie"] = this.dataSource["pie"].sort((a,b) => b.value - a.value);
            this.types = res.data.types;
          })
          .catch(e => {
            this.isEmpty = true;
            this.dataSource = {
                line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
                pie: [{type: "Install", value: 549, time: 549}]
            };
            this.types = ["Install", "Upgrade", "Uninstall"];
          });
      })
      .catch(e => {
        this.isEmpty = true;
        this.dataSource = {
            line: [{type: "Install", time: "2021-06-06T00:00:00.000Z", value: 13, cumcount: 363}],
            pie: [{type: "Install", value: 549, time: 549}]
        };
        this.types = ["Install", "Upgrade", "Uninstall"];
      });

    
  }
}
</script>

