<template>
    <a-layout class="layout">
    <a-layout-header
        style="background:#fff"
        theme="light"
    >
      <div class="logo">
        <img src="http://127.0.0.1:8000/media/favicon.png" />
        Kronozio Uninstall Survey
      </div>
    </a-layout-header>

    <a-layout-content style="padding: 0 50px">
        <div :style="{ padding: '24px', minHeight: '90vh', width:'100%'}">
            <a-result
                status="success"
                title="Successfully Submitted"
                v-if="this.submitted"
            > 
            </a-result>
            <a-card style="margin-top: 20pt; width:40%; align-text=center; margin-left:auto; margin-right:auto" v-else-if="this.uninstallReasonsSuccess">
                <div >
                    <a-radio-group v-if="Object.keys(this.uninstallReasons).length > 0" 
                        v-model:value="selectedReason" 
                        style="align-text:start"
                    >
                        <div v-for="(i, reason) in this.uninstallReasons" >
                            <a-radio-button :style="radioStyle" :value="reason"> 
                                {{ this.uninstallReasons[reason] }} 
                            </a-radio-button>
                            
                        </div>
                        
                    </a-radio-group>

                    <a-spin :indicator="indicator" v-else />
                </div>
                <a-input v-if="selectedReason == Object.keys(this.uninstallReasons).length" 
                    style="width: 400px; margin-top: 25px" 
                    placeholder="Please enter your reason"
                    v-model:value="notes"
                />
                <br/>
                <a-button type="primary" style="margin-top: 30px" @click="onClickSubmit">
                    Submit
                </a-button>
            </a-card>

            <a-result v-else status="warning" title="Something wrong on our side, please try again later.">
            </a-result>
        </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      Kronocard Admin Panel ©2021 Created by Yizhou Zhao
    </a-layout-footer>
    </a-layout>
    
</template>

<script>

import {defineComponent, onMounted, ref, reactive, getCurrentInstance} from 'vue';
import { useRoute } from 'vue-router'
export default defineComponent({
  name: "UninstallSurvey", 
  components: {
  },
  setup(props, context){
    const { ctx }  = getCurrentInstance();
    const route = useRoute();
    const installationID = route.params.installation_id;
    const activityID = route.params.activity_id;

    // variables of the form
    const notes = ref('');
    const selectedReason = ref(1);
    const radioStyle = reactive({
      display: 'block',
      height: '30px',
      width: '400px',
      'margin-top': "10px",
      lineHeight: '30px',
    });

    // uninstall reasons 
    const uninstallReasonsSuccess = ref(true);
    const uninstallReasons = ref([]);
    const submitted = ref(false);

    const onClickSubmit = () => {
        console.log(installationID, activityID);
        ctx._.appContext.config.globalProperties.$post(`/api/kronocard/uninstallsurvey/${installationID}/${activityID}/`, 
            {
                "uninstall_reason": selectedReason.value,
                "notes": notes.value
            })
            .then(res => {
                submitted.value = true;
            }).catch(e => {
                ctx._.appContext.config.globalProperties.$message.error("Unable to submit, please try again");
            })
    };
    
    const fetchAllUninstallReason = () => {
        ctx._.appContext.config.globalProperties.$get("/api/uninstall-reasons/")
            .then(res => {
                uninstallReasons.value = res.data;
                uninstallReasonsSuccess.value = true;
                console.log(uninstallReasons.value);
            })
            .catch(e => {
                uninstallReasonsSuccess.value = false;
            })
    }

    onMounted(fetchAllUninstallReason);

    return {selectedReason, notes, radioStyle, uninstallReasons, uninstallReasonsSuccess, onClickSubmit, submitted };
  },
})
</script>