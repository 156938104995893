<template>
    <div :id="chartId"></div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUpdated, onBeforeUnmount, inject, nextTick } from 'vue'
import { Line } from '@antv/g2plot';
import { v4 as uuid4 } from 'uuid';

export default defineComponent({
    name: "BasicLineChart",
    props:{
        dataSource: Array,
        xField: Array,
        yField: Array,
    },

    data(){
        return{
            id: uuid4(),
        }
    },

    setup(props){
        // initialize the lineChart ref
        const chart = ref('');
        const chartId = uuid4();
        onMounted(async () => {
            await nextTick();
            chart.value = new Line(chartId, {
                data: props.dataSource,
                padding: 'auto',
                height: props.height ? props.height : 200,
                xField: props.xField,
                yField: props.yField
            });
            chart.value.render();
        });
        onBeforeUnmount(() => {
            chart.value.destroy();
        });

        return {chartId};
    }
})
</script>